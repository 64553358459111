export const positionIcons = {
	1: 'attacker-role-icon.png',
	2: 'defender-role-icon.png',
	3: 'specialist-role-icon.png',
}

export const rarity = {
	1: {
		detailsBackgroundDesktop: 'common-background-wedge.png',
		detailsBackgroundMobile: 'common-background-wedge-mobile.png',
		glowBackground: 'common-glow-background.png',
		backgroundColor: 'common-main-background.png',
		tags: [
			'common-tag-en.png',
			'common-tag-es.png'
		],
		cardPack: 'common-shiny.png'
	},
	2: {
		detailsBackgroundDesktop: 'uncommon-background-wedge.png',
		detailsBackgroundMobile: 'uncommon-background-wedge-mobile.png',
		glowBackground: 'uncommon-glow-background.png',
		backgroundColor: 'uncommon-main-background.png',
		tags: [
			'uncommon-tag-en.png',
			'uncommon-tag.es.png'
		],
		cardPack: 'uncommon-shiny.png'
		},
	3: {
		detailsBackgroundDesktop: 'rare-background-wedge.png',
		detailsBackgroundMobile: 'rare-background-wedge-mobile.png',
		glowBackground: 'rare-glow-background.png',
		backgroundColor: 'rare-main-background.png',
		tags: [
			'rare-tag-en.png',
			'rare-tag-es.png'
		],
		cardPack: 'rare-shiny.png'
	},
	4: {
		detailsBackgroundDesktop: 'epic-background-wedge.png',
		detailsBackgroundMobile: 'epic-background-wedge-mobile.png',
		glowBackground: 'epic-glow-background.png',
		backgroundColor: 'epic-main-background.png',
		tags: [
			'epic-tag-en.png',
			'epic-tag-es.png'
		],
		cardPack: 'epic-shiny.png'
		},
	5: {
		detailsBackgroundDesktop: 'legendary-background-wedge-desktop.png',
		detailsBackgroundMobile: 'legendary-background-wedge-mobile.png',
		glowBackground: 'legendary-glow-background.png',
		backgroundColor: 'legendary-main-background.png',
		tags: [
			'legendary-tag-en.png',
			'legendary-tag-es.png'
		],
		cardPack: 'legendary-shiny.png'
		}
}

export const MokenFamilyLogos = {
	Alien: 'Aliens.png' ,
	Animal: 'Animal.png',
	Historical: 'Arcane.png',
	Elementals: 'Elemental.png',
	Gen1: 'Originals-family-logo.png',
	Halloween: 'Halloween.png',
	Robots: 'Robots.png',
}