import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { average } from 'common/utils';
import { getBucketImage } from 'common/utils';
import { IMokenCardProps } from 'common/types';
import { cardBackground, sizes } from '@global/MokenCard/mokenCardData';
import { POSITION_ICON } from 'common/const';

import { GatsbyImage } from 'gatsby-plugin-image';

import './styles.scss';

const MokenCard: FC<IMokenCardProps> = ({ mokenData, size, disabled, className, handleOnClick, imageData }) => {
	const { Position, MokenName, GraphicId, Rarity, STR, INT, STA, ABL } = mokenData;

	const { t } = useTranslation();

	const mokenOverallScore = average([STR, INT, STA, ABL])

	return (
		<div onClick={() => handleOnClick(disabled ? null : mokenData)}
			className={`relative
				flex
				items-center
				justify-center
				overflow-hidden
				${className}
				${sizes.width[size]}
				${disabled && 'grayscale pointer-events-none select-none'}
			`}
		>
			<div className='background-image'>
				<GatsbyImage
					loading='eager'
					image={getBucketImage(imageData, cardBackground[Rarity], 'imageData')}
					alt={t('mokenCard.mokenImage')}
					className='-z-1 absolute self-center w-full h-full'
					style={{
						backgroundSize: 'contain',
						backgroundPosition: 'center',
					}}
				/>
				<p
					className={`absolute
					z-1
					w-[12%]
					top-[5%]
					left-[11%]
					align-top
					font-extrabold
					text-white
					${sizes.overallSize[size]}
					`}
				>
					{mokenOverallScore}
				</p>
				<GatsbyImage
					loading='eager'
					alt={t('mokenCard.mokenPosition')}
					className='w-[14%] absolute top-[7%] right-[10%]'
					image={getBucketImage(imageData, POSITION_ICON[Position], 'imageData')}
				/>
				<GatsbyImage
					loading='eager'
					image={getBucketImage(imageData, `${GraphicId}.png`, 'imageData')}
					alt={t('mokenCard.mokenImage')}
					className='object-cover -z-1 h-full self-center'
				/>
				<p className={`
						absolute
						w-[85%]
						overflow-visible
						self-center
						bottom-[9%]
						uppercase
						text-center
						text-auto
						text-white
						${sizes.nameSize[size]}
					`}
				>
					{MokenName}
				</p>
				{disabled && <div className='absolute w-full h-full bg-white bg-opacity-40' />}
			</div>
		</div>
	);
}

export default MokenCard;