import React, { useEffect } from 'react';
import usePagination from 'common/usePagination';
import useMediaChange from 'common/useMediaChange';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Pagination as MuiPagination } from '@mui/material'

import './styles.scss';

const theme = createTheme({
	palette: {
		primary: {
			main: '#ffffff',
			contrastText: '#003C84'
		},
		text: {
			primary: '#ffffff',
		},
	},
	typography: {
		fontFamily: 'Public Sans Round',
		fontWeightRegular: '800',
	}
});

const Pagination = ({ items, pageLimit, setPageItems, className }) => {
	const {
		pageCount,
		currentPage,
		changePage,
		pageData,
		nextPageData,
	} = usePagination(items, pageLimit);

	const { isMobile } = useMediaChange();

	useEffect(() => {
		if (currentPage > pageCount) {
			changePage(pageCount)
		}
		setPageItems({ currentPage: pageData(), nextPage: nextPageData() });
	}, [currentPage, pageLimit]);

	return (
		<>
			{pageCount > 1 &&
				<div className={className}>
					<ThemeProvider theme={theme}>
						<MuiPagination
							size={isMobile ? 'small' : 'medium'}
							siblingCount={isMobile ? 0 : 1}
							count={pageCount}
							color='primary'
							className='rounded-2xl bg-blue-dull'
							page={currentPage}
							onChange={(e, page) => {
								changePage(page);
							}}
						/>
					</ThemeProvider>
				</div>
			}
		</>
	);
};

export default Pagination;