import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { COLLECTION_HERO_SECTION } from 'common/const';

import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, StaticImage, withArtDirection } from 'gatsby-plugin-image';

import './styles.scss';

const CollectionHeroSection: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();

	const textImage = withArtDirection(getBucketImage(imagesData, 'collection-hero-section-text-desktop.png', 'imageData'), [
		{
			media: '(max-width: 640px)',
			image: getBucketImage(imagesData, 'collection-hero-section-text-mobile.png', 'imageData'),
		}
	]);

	const backgroundImage = [
		getBucketImage(imagesData, 'collection-hero-section-background-desktop.png', 'fluidData'),
		{
			...getBucketImage(imagesData, 'collection-hero-section-background-mobile.png', 'fluidData'),
			media: '(max-width: 1024px)',
		},
	];

	return (
		<BackgroundImage
			Tag='section'
			fluid={backgroundImage}
			alt={t('collectionHeroSection.background')}
			className='collection-background'
			id={COLLECTION_HERO_SECTION}
		>
			<div className='flex flex-col items-center w-full safe-space-container' >
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/basic-mokens-logo.png'
					alt={t('collectionHeroSection.mainTitle')}
					objectFit='contain'
					placeholder='blurred'
					loading='eager'
					className='absolute top-0 h-auto w-full sm:w-2/5'
				/>
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/collection-hero-section-decorative.png'
					alt={t('collectionHeroSection.centerImage')}
					objectFit='contain'
					placeholder='blurred'
					loading='eager'
					className='absolute top-1/4 w-auto sm:top-0 sm:h-full'
				/>
				<GatsbyImage
					image={textImage}
					alt={t('collectionHeroSection.decoratedText')}
					objectFit='contain'
					loading='eager'
					className='absolute bottom-[10%] w-full aspect-[3.6] sm:w-4/5 sm:aspect-auto'
				/>
			</div>
		</BackgroundImage>
	);
}

export default CollectionHeroSection;