import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import useMediaChange from 'common/useMediaChange';
import { getBucketImage } from 'common/utils';
import { IDetailsInfo, IMokenDetailProps } from 'common/types';
import { rarity, MokenFamilyLogos } from './mokenData';
import {POSITION_ICON} from 'common/const';

import IconButton from '@mui/material/IconButton';
import OverallScore from './OverallScore';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import StatBox from './StatBox';
import LangDepImg from '@global/LangDepImg';

import CloseIcon from 'images/close-red.svg';

import './styles.scss';

const MokenDetail: FC<IMokenDetailProps> = ({ moken, closeModal, className = '', imagesData }) => {
	const { isTablet } = useMediaChange();
	const { t } = useTranslation();

	const scores = [moken.STR, moken.STA, moken.ABL, moken.INT];
	const avgScore = Math.round((moken.INT + moken.ABL + moken.STA + moken.STR) / 4);
	const titles: IDetailsInfo[] = t('mokenDetails.titles', { returnObjects: true });
	const imageAlts: IDetailsInfo[] = t('mokenDetails.imageAlts', { returnObjects: true });

	const scoreImages = [
		'boxing-glove-icon.png',
		'heart-icon.png',
		'cog-icon.png',
		'scroll-icon.png'
	];

	const rarities = [
		'common',
		'uncommon',
		'rare',
		'epic',
		'legenday'
	];

	const positions = [
		'at',
		'df',
		'sp'
	];

	return (
		<div
			className={`
				flex
				relative
				flex-col
				aspect-[0.56]
				border-2
				border-white
				rounded-3xl
				justify-around
				max-w-[95vw]
				max-h-[90vh]
				md:aspect-[1.76]
				md:flex-row
				${className}
			`
			}
		>
			<div className='absolute w-full h-full -z-1 overflow-hidden rounded-3xl'>
				<GatsbyImage
					image={getBucketImage(imagesData, rarity[moken.Rarity].backgroundColor, 'imageData')}
					alt={t('mokenDetails.mokenImage')}
					className='
					absolute
					w-full
					h-[50%]
					cut-background
					md:h-full
				'
				/>
				{isTablet ? <GatsbyImage
					image={getBucketImage(imagesData, rarity[moken.Rarity].detailsBackgroundMobile, 'imageData')}
					alt={t('mokenDetails.mokenImage')}
					className='
					absolute
					bottom-0
					h-3/5
					z-10
				'
				/>
					: <GatsbyImage
						image={getBucketImage(imagesData, rarity[moken.Rarity].detailsBackgroundDesktop, 'imageData')}
						alt={t('mokenDetails.mokenImage')}
						objectPosition={'0% 50%'}
						className='
							absolute
							h-full
							right-0
							w-3/5
							z-10
						'
					/>}
			</div>

			{
				isTablet &&
				(
					<IconButton
						className='
							absolute
							-right-6
							-top-6
							w-14
						'
						onClick={closeModal}>
						<div className='w-full z-50'>
							<img src={CloseIcon} alt={t('mokensUniverse.closeIcon')} />
						</div>
					</IconButton>
				)
			}

			<GatsbyImage
				image={getBucketImage(imagesData, MokenFamilyLogos[moken.Collection[0]], 'imageData')}
				alt={t('mokenDetails.familyLogo')}
				className='
					absolute
					w-20
					top-4
					right-4
					md:w-24
					lg:w-28
					lg:top-8
					xl:w-40
				'
			/>

			<BackgroundImage
				Tag='div'
				fluid={getBucketImage(imagesData, rarity[moken.Rarity].glowBackground, 'fluidData')}
				className='flex items-center w-full h-full md:w-2/5'
				style={{
					backgroundSize: 'contain',
				}}
			>
				<GatsbyImage
					image={getBucketImage(imagesData, moken.GraphicId, 'imageData')}
					alt={t('mokenDetails.mokenImage')}
					objectFit='cover'
					objectPosition='50% 50%'
				/>
			</BackgroundImage>

			<div
				className='
					flex
					flex-col
					w-full
					h-full
					py-2
					pb-auto
					md:w-1/2
					md:py-8
					'
			>
				<div className='mb-2 w-full px-7'>
					<h2
						className='
							moken-name
							flex
							items-center
							justify-start
							w-full
							text-white
							whitespace-nowrap
							uppercase
							leading-3
							tracking-wide
							text-[2rem]
							md:text-[3vw]
						'
					>
						{moken.MokenName}
						<GatsbyImage
							alt={t(`mokenDetails.positions.${positions[moken.Position - 1]}`)}
							className='
								mx-2
								max-w-[2rem]
								lg:mx-4
								xl:max-w-[4rem]
							'
							image={getBucketImage(imagesData, POSITION_ICON[moken.Position], 'imageData')}
						/>
					</h2>
					<LangDepImg
						images={[getBucketImage(imagesData, rarity[moken.Rarity].tags[0], 'imageData'), getBucketImage(imagesData, rarity[moken.Rarity].tags[1], 'imageData')]}
						imgAlt={t(`mokenDetails.rarities.${rarities[moken.Rarity - 1]}`)}
						objectFit='contain'
						objectPosition='0% 50%'
						className={isTablet ? 'rarity-tag-mobile' : 'rarity-tag-desktop'}
					/>
				</div>

				<div className='flex
					items-center
					justify-self-center
					h-full
					md:items-start
					md:mt-6
					xl:mt-12
				'>
					<div className='flex
						flex-col
						w-[57%]
						pl-7
						space-y-2
						md:space-y-4
						lg:space-y-4
						xl:space-y-8
					'>
						<OverallScore
							score={avgScore}
							image={getBucketImage(imagesData, 'lightning-icon.png', 'imageData')}
						/>
						<div className='flex flex-col space-y-2 md:space-y-4'>
							{
								scores.map((score, index) => (
									<StatBox
										label={titles[index].info}
										icon={getBucketImage(imagesData, scoreImages[index], 'imageData')}
										iconAlt={imageAlts[index].info}
										value={score}
										key={titles[index].info}
									/>
								))
							}
						</div>
					</div>
					<div className='flex flex-1 w-full'>
						<GatsbyImage
							alt={t('mokenDetails.pack')}
							className='mb-auto rotate-6'
							image={getBucketImage(imagesData, rarity[moken.Rarity].cardPack, 'imageData')}
						/>
					</div>
				</div>
			</div>
		</div>
	)
};

export default MokenDetail;