import React from "react";

import { sizes } from '@global/MokenCard/mokenCardData';

const LastRowFill = ({ spacesToFill, size }) => {
	return (
		<>
			{Array.from(Array(spacesToFill), (_, index) => <div className={`h-0 ${size}`} key={index} />)}
		</>
	)
}

export default LastRowFill;
