import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { useUserContext } from 'common/UserProvider';
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from '@mui/material';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { getBucketImage, navigateRedirect } from 'common/utils';
import { init } from 'common/handleLogin';
import { LOGIN_URL, COLLECTION_HERO_SECTION, COLLECTION_MARKETPLACE_SECTION, COLLECTION_URL } from 'common/const';
import { FooterTheme } from 'common/context';
import { FooterStyle } from 'common/types';

import Header from '@layout/Header';
import Footer from '@layout/Footer';
import MokenDetail from '@global/MokenDetail';
import MarketplaceSection from '@sections/Collection/MarketplaceSection';
import CollectionHeroSection from '@sections/Collection/CollectionHeroSection';
import Spinner from '@global/Spinner';
import BackgroundImage from 'gatsby-background-image';
import FamiliesSection from '@sections/Collection/FamiliesSection';

import './styles.scss'

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
    allS3Object {
      nodes {
        localFile {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
	}
`;

const Collection = ({ data }) => {
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [canClose, setCanClose] = useState<boolean>(false);
	const [currentMokenData, setCurrentMokenData] = useState();
	const [showSpinner, setSpinner] = useState(true)
	const [error, setError] = useState('');

	const { loaded, user, setUser } = useUserContext();
	const { language, languages, t } = useI18next();

const links = [
	{ title: 'footer.collection.hero', url: `${COLLECTION_URL}#${COLLECTION_HERO_SECTION}` },
	{ title: 'footer.collection.marketplace', url: `${COLLECTION_URL}#${COLLECTION_MARKETPLACE_SECTION}` },
]

	useEffect(() => {
		if (error) toast.error(error);
	}, [error]);

	useEffect(() => {
		if (window.location) {
			init(language, languages).then(res => {
				if (res.error) setError(res.error);
				if (res.user) setUser(res.user);
			}).catch(err => { return err })
		}
	}, []);

	useEffect(() => {
		if (loaded && !user) {
			navigate(navigateRedirect(LOGIN_URL, language, languages), { state: { redirectAfterLogin: location.href } });
		} else if (loaded && user) { setSpinner(false) }
	}, [loaded, user])

	const handleOpen = () => {
		setCanClose(false);
		setModalOpen(true);
		setTimeout(() => {
			setCanClose(true);
		}, 150);
	}

	const handleClose = () => {
		if (!canClose) return;
		setModalOpen(false);
		setCurrentMokenData(null)
	}

	useEffect(() => {
		if (currentMokenData) {
			handleOpen();
		}
	}, [currentMokenData])

	return (
		<>
			<main>
				<Toaster />
				<Header />
				{showSpinner ?
					(
						<BackgroundImage
							Tag='div'
							fluid={getBucketImage(data, 'Stadiums-background.png', 'fluidData')}
							className='spinner-background'
						>
							<Spinner className='w-1/4 self-center' />
						</BackgroundImage>
					) :
					(
						<div className='flex flex-col'>
							<CollectionHeroSection imagesData={data} />
							<MarketplaceSection imagesData={data} />
							<FamiliesSection
								handleSelectMoken={(moken) => setCurrentMokenData(moken)}
								imagesData={data}
							/>
							<FooterTheme.Provider value={FooterStyle.DARK}>
								<Footer links={links} />
							</FooterTheme.Provider>
							<Modal open={isModalOpen} onClose={() => handleClose()} className='flex items-center justify-center'>
								<div className='flex flex-col'>
									<MokenDetail imagesData={data} moken={currentMokenData} closeModal={() => handleClose()} />
								</div>
							</Modal>
						</div>
					)
				}
			</main>
		</>
	)
}

export default Collection;