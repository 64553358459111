import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { COLLECTION_MARKETPLACE_SECTION, HOME_URL } from 'common/const';

import { StylizedButton } from '@global/Buttons';
import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';

import './styles.scss'

const MarketplaceSection: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();

	return (
		<BackgroundImage
			Tag='section'
			fluid={getBucketImage(imagesData, 'moken-head-icons-pattern.png', 'fluidData')}
			className='marketplace-section-background'
			id={COLLECTION_MARKETPLACE_SECTION}
		>
			<div className='flex flex-col items-center lg:flex-row safe-space-container'>
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/rewards-pack.png'
					alt={t('albumSection.images.mokensBox')}
					placeholder='none'
					className='aspect-[1.21] image-desktop'
				/>
				<div className='
						flex
						flex-col
						justify-center
					'
				>
					<h1 className='title-line title-line-first'>
						{t('albumSection.outlinedTitle')}
					</h1>
					<h1 className='title-line title-line-second'>
						{t('albumSection.filledTitle')}
					</h1>
					<p className='marketplace-text'>
						{t('albumSection.paragraph.part1')}
					</p>
					<p className='marketplace-text'>
						{t('albumSection.paragraph.part2')}
					</p>
					<StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/rewards-pack.png'
						alt={t('albumSection.images.mokensBox')}
						placeholder='none'
						className='aspect-[1.21] image-mobile'
					/>
					<div className='flex justify-center w-full lg:justify-start lg:w-2/3' >
						<StylizedButton
							className='call-to-action'
							link={HOME_URL}
							text={t('newMarketplaceSection.cta')}
							disabled
						/>
					</div>
				</div>
			</div>
			<StaticImage
				src="https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/black-wedge.png"
				alt={t('mokenFamily.wedge')}
				placeholder='none'
				className='absolute -bottom-1 w-full -scale-x-100'
			/>
		</BackgroundImage>
	)
}

export default MarketplaceSection;