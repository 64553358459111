import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IFamilyData, IMokensFamiliesSection } from 'common/types';
import { getBucketImage } from 'common/utils';

import Family from '@sections/Collection/Family';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss'

const FamiliesSection: FC<IMokensFamiliesSection> = ({
	handleSelectMoken,
	imagesData
}) => {

	const { t } = useTranslation();

	const familyData: IFamilyData[] = [
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-space-fighters.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'space-fighters-summary-0.png', 'imageData'),
				getBucketImage(imagesData, 'space-fighters-summary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'space-fighters-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'space-fighters-pattern-background.png', 'fluidData'),
			dividerImage: 'pink-magenta-divider',
			familyLogo: getBucketImage(imagesData, 'space-fighters-logo.png', 'imageData'),
			familyName: 'Alien'
		},
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-wild-beasts.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'wild-beasts-summary-0.png', 'imageData'),
				getBucketImage(imagesData, 'wild-beasts-summary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'wild-beasts-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'wild-beasts-pattern-background.png', 'fluidData'),
			dividerImage: 'magenta-pink-divider',
			familyLogo: getBucketImage(imagesData, 'wild-beasts-logo.png', 'imageData'),
			familyName: 'Animal'
		},
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-elemental-origins.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'elementals-summary-0.png', 'imageData'),
				getBucketImage(imagesData, 'elementals-summary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'elemental-origins-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'elemental-pattern-background.png', 'fluidData'),
			dividerImage: 'magenta-teal-divider',
			familyLogo: getBucketImage(imagesData, 'elemental-origins-logo.png', 'imageData'),
			familyName: 'Elementals'
		},
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-halloween-origins.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'halloween-sumary-0.png', 'imageData'),
				getBucketImage(imagesData, 'halloween-sumary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'halloween-origins-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'halloween-pattern-background.png', 'fluidData'),
			dividerImage: 'blue-teal-divider',
			familyLogo: getBucketImage(imagesData, 'halloween-origins-logo.png', 'imageData'),
			familyName: 'Halloween'
		},
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-arcane-warriors.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'arcane-warriors-summary-0.png', 'imageData'),
				getBucketImage(imagesData, 'arcane-warriors-summary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'arcane-warriors-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'arcane-warriors-pattern-background.png', 'fluidData'),
			dividerImage: 'teal-green-divider',
			familyLogo: getBucketImage(imagesData, 'arcane-warriors-logo.png', 'imageData'),
			familyName: 'Historical'
		},
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-engines-bolts.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'engines-summary-0.png', 'imageData'),
				getBucketImage(imagesData, 'engines-summary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'engines-and-bolts-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'engines-pattern-background.png', 'fluidData'),
			dividerImage: 'green-orange-divider',
			familyLogo: getBucketImage(imagesData, 'engines-and-bolts-logo.png', 'imageData'),
			familyName: 'Robots'
		},
		{
			summaryBg: getBucketImage(imagesData, 'family-sumary-bg-the-originals.png', 'fluidData'),
			sumaryMokenLineups: [
				getBucketImage(imagesData, 'the-originals-summary-0.png', 'imageData'),
				getBucketImage(imagesData, 'the-originals-summary-1.png', 'imageData')
			],
			backgroundImageFluid: getBucketImage(imagesData, 'the-originals-family-background', 'fluidData'),
			backgroundPattern: getBucketImage(imagesData, 'the-originals-pattern-background.png', 'fluidData'),
			dividerImage: 'orange-yellow-divider',
			familyLogo: getBucketImage(imagesData, 'the-originals-logo.png', 'imageData'),
			familyName: 'Gen1'
		}
	];

	return (
		<section>
			<BackgroundImage
				Tag='div'
				fluid={getBucketImage(imagesData, 'collection-families-header-bg.png', 'fluidData')}
				className='title-background'
			>
				<StaticImage
					src="https://mokens-landing-bucket.s3.amazonaws.com/images/logos/the-mokens-logo.png"
					alt={t('mokenFamily.mokensLogo')}
					placeholder='none'
					className='w-1/3 my-[3%]'
				/>
			</BackgroundImage>
			<div className='divide-y-4'>
				{
					familyData.map(family => (
						<Family
							key={family.familyName}
							imagesData={imagesData}
							setMokenData={(moken) => handleSelectMoken(moken)}
							familyData={family}
						/>
					))
				}
			</div>
			<BackgroundImage
				Tag='div'
				fluid={getBucketImage(imagesData, 'collection-families-header-bg.png', 'fluidData')}
				className='w-full flex flex-col items-center'
			>
				<StaticImage
					src="https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/black-wedge.png"
					alt={t('mokenFamily.wedge')}
					placeholder='none'
					className='w-full self-start rotate-180 mb-4'
				/>
				<StaticImage
					src="https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/black-wedge.png"
					alt={t('mokenFamily.wedge')}
					placeholder='none'
					className='w-full self-start bottom-[-1px]'
				/>
			</BackgroundImage>
		</section>
	)
}

export default FamiliesSection;
