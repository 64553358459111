import React, { FC, useState } from 'react';
import useMediaChange from 'common/useMediaChange';

import { IPaginatedGrid } from 'common/types';

import MokenCard from '@global/MokenCard';
import Pagination from '@global/Pagination';
import LastRowFill from '@sections/Collection/Family/LastRowFill';
import { sizes } from '@global/MokenCard/mokenCardData';

const PaginatedGrid: FC<IPaginatedGrid> = ({
	setMokenData,
	mokenArray,
	ownedMokensIds,
	imagesData
}) => {
	const [pageMokens, setPageMokens] = useState({ currentPage: [], nextPage: [] });
	const { isTablet, isMobile } = useMediaChange();

	const pageLimit = isMobile ? 6 : 12;

	const cardSize = () => {
		return isTablet ? 'xs' : 'sm'
	}

	const checkIfOwned = (id: number) => {
		if (!ownedMokensIds) return;
		return ownedMokensIds.includes(id);
	}

	return (
		<div
			className='
					w-full
					flex
					flex-col
					flex-grow
					'
		>
			<div
				className='
						flex
						flex-wrap
						flex-grow
						justify-around
						items-start
						w-full
						pt-2
						px-2
						gap-x-2
						gap-y-4
						sm:gap-x-4
						sm:px-4
				'>

				{
					pageMokens.currentPage.map((moken) => {
						return <MokenCard
							imageData={imagesData}
							mokenData={moken}
							size={cardSize()}
							handleOnClick={setMokenData}
							disabled={!checkIfOwned(moken.Id)}
							key={moken.Id}
						/>
					})
				}

				<div className='hidden'>
					{
						pageMokens.nextPage.map((moken) => {
							return <MokenCard
								imageData={imagesData}
								mokenData={moken}
								size={cardSize()}
								handleOnClick={setMokenData}
								disabled={!checkIfOwned(moken.Id)}
								key={moken.Id}
							/>
						})
					}
				</div>
				<LastRowFill spacesToFill={8} size={sizes.width[cardSize()]} />
			</div>
			<Pagination
				className='
						mx-auto
						sticky
						mb-6
						cursor-pointer
						bottom-6
					'
				items={mokenArray}
				pageLimit={pageLimit}
				setPageItems={setPageMokens}
			/>
		</div>
	)
};

export default PaginatedGrid;