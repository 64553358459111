import { navigate } from 'gatsby';
import jwtDecode from 'jwt-decode';
import { emailStatus } from 'common/server';

import {
	USER_LOCAL_STORAGE_KEY,
	LOGIN_FLOW,
	LOGIN_ERROR_MSG,
	SINGUP_FLOW,
	SINGUP_ERROR_MSG,
	LINK_FLOW,
	LINK_URL
} from 'common/const';
import { navigateRedirect } from './utils';

export const init = async (language: string, languages: string[]) => {
	if (!window.location.search) return;

	let res = { error: null, user: null };

	const queryParams = new URLSearchParams(window.location.search);
	const accessToken = queryParams.get('access_token');
	const refreshToken = queryParams.get('refresh_token');
	const idToken = queryParams.get('id_token');
	const flow = queryParams.get('flow');
	const error = queryParams.get('error');

	if (error) {
		if (
			flow === LOGIN_FLOW &&
			error === LOGIN_ERROR_MSG
		) {
			navigate(navigateRedirect(LINK_URL, language, languages), {
				state: {
					flow: flow,
				},
			});
			return;
		}

		if (
			flow === SINGUP_FLOW &&
			error === SINGUP_ERROR_MSG
		) {
			navigate(navigateRedirect(LINK_URL, language, languages), {
				state: {
					flow: flow,
				},
			});
			return;
		}

		return res = { ...res, error: error };
	}

	if (flow === LINK_FLOW) {
		return;
	}

	if (accessToken) {
		localStorage.setItem(
			USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN',
			accessToken
		);
		localStorage.setItem(
			USER_LOCAL_STORAGE_KEY + '_REFRESH_TOKEN',
			refreshToken
		);
		localStorage.setItem(USER_LOCAL_STORAGE_KEY + '_ID_TOKEN', idToken);

		let decodedIdToken;
		if (idToken) decodedIdToken = jwtDecode(idToken);

		if (
			decodedIdToken &&
			decodedIdToken.exp > Date.now().valueOf() / 1000
		) {
			const currentUser = await emailStatus({
				email: decodedIdToken.email,
			});
			if (currentUser) {
				localStorage.setItem(
					USER_LOCAL_STORAGE_KEY + '_EMAIL',
					decodedIdToken.email
				);
				return res = {
					...res,
					user: {
						...currentUser,
						id_token: idToken,
						access_token: accessToken,
						refresh_token: refreshToken
					}
				};
			}
		} else {
			localStorage.clear();
		}
	} else {
		localStorage.clear();
	}
};
