import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IFamilyProps } from 'common/types';
import { getMokensFamily } from 'common/server';
import { getBucketImage } from 'common/utils';

import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import PaginatedGrid from '@sections/Collection/Family/PaginateGrid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import './styles.scss';

const Family: FC<IFamilyProps> = ({
	setMokenData,
	imagesData,
	familyData
}) => {
	const {
		familyLogo,
		familyName,
		summaryBg,
		sumaryMokenLineups,
		backgroundPattern
	} = familyData;

	const { t } = useTranslation();
	const [familyMokens, setFamilyMokens] = useState<any>();
	const [ownedMokensIds, setOwnedMokensIds] = useState([]);
	const [expanded, setExpanded] = useState<boolean>(false)

	useEffect(() => {
		const receiveMokensList = async () => {
			const result = await getMokensFamily(familyName);
			if (!result) return;
			const { AllMokensByFamily, UserMokensByFamily } = result;

			const uniqueMokenIds = new Set(UserMokensByFamily.map(moken => moken.Id));
			setOwnedMokensIds([...uniqueMokenIds])

			const checkIfOwned = (id: number) => {
				const owned = [...uniqueMokenIds]
				return owned.includes(id);
			}

			const sortMokens = (firstId: number, secondId: number) => {
				const firstMoken = checkIfOwned(firstId);
				const secondMoken = checkIfOwned(secondId);
				if (firstMoken === secondMoken) return 0;
				if (firstMoken && !secondMoken) return -1;
				else return 1;
			}

			const SortedMokens = AllMokensByFamily.sort((moken, secondMoken) => sortMokens(moken.Id, secondMoken.Id))

			setFamilyMokens({ SortedMokens, UserMokensByFamily });
		}
		receiveMokensList();
	}, [])

	const getOwnedIds = () => {
		const uniqueMokenIds = new Set(familyMokens?.UserMokensByFamily.map(moken => moken.Id));
		setOwnedMokensIds([...uniqueMokenIds])
	}

	useEffect(() => {
		getOwnedIds();
	}, [familyMokens])

	const handleChange = () => {
		setExpanded(!expanded);
	};

	return (
		<Accordion
			square
			disableGutters
			expanded={expanded}
			onChange={() => handleChange()}
			sx={{
				'.MuiButtonBase-root': {
					padding: 0,
					minHeight: 0
				},
			}}
		>
			<AccordionSummary
				sx={{
					'.MuiAccordionSummary-content': {
						margin: '0',
					},
				}}
			>
				<BackgroundImage
					Tag='section'
					fluid={summaryBg}
					alt={t('mokenFamily.familySectionBackground')}
					className='summary-background'
				>
					<BackgroundImage
						Tag='div'
						style={{
							backgroundSize: 'contain',
						}}
						fluid={getBucketImage(imagesData, 'orange-button', 'fluidData')}
						alt={t('mokenFamily.familySectionBackground')}
						className='counter-bg'
					>
						<p className='
							text-white
							text-center
							w-full
							uppercase
							tracking-tighter
							font-bold
							font-sans-round
							italic
							text-[0.5rem]
							lg:text-[2rem]
							'
						>
							{ownedMokensIds.length}-{familyMokens?.SortedMokens.length}
						</p>
					</BackgroundImage>
					<GatsbyImage
						image={sumaryMokenLineups[0]}
						objectFit='contain'
						alt={t('mokenFamily.gen1')}
						className='max-h-[200px] w-[35%] self-end mx-4'
					/>
					<GatsbyImage
						image={familyLogo}
						alt={t('mokenFamily.gen1')}
						className='w-[10%] max-h-[170px]'
						objectFit='contain'
					/>
					<GatsbyImage
						image={sumaryMokenLineups[1]}
						objectFit='contain'
						alt={t('mokenFamily.gen1')}
						className='max-h-[200px] w-[35%] mx-4 self-end'
					/>
					<BackgroundImage
						Tag='div'
						style={{
							backgroundSize: 'contain',
						}}
						fluid={getBucketImage(imagesData, 'yellow-square-button', 'fluidData')}
						alt={t('mokenFamily.familySectionBackground')}
						className='button-bg'
					>
						{
							expanded
								? <StaticImage
									src='https://mokens-landing-bucket.s3.amazonaws.com/images/icons/minus.png'
									loading='eager'
									className='w-1/2'
									placeholder='none'
									alt={t('faqSection.minus')}
								/>
								: <StaticImage
									src='https://mokens-landing-bucket.s3.amazonaws.com/images/icons/plus.png'
									loading='eager'
									className='w-1/2'
									placeholder='none'
									alt={t('faqSection.plus')}
								/>
						}
					</BackgroundImage>
				</BackgroundImage>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					width: '100%',
					padding: '0',
					margin: '0'
				}}
			>
				<div>
					<BackgroundImage
						Tag='div'
						style={{
							backgroundSize: 'cover',
						}}
						fluid={backgroundPattern}
						alt={t('mokenFamily.familySectionBackground')}
						className='grid-background'
					>
						{familyMokens &&
							<PaginatedGrid
								imagesData={imagesData}
								setMokenData={setMokenData}
								mokenArray={familyMokens?.SortedMokens}
								ownedMokensIds={ownedMokensIds}
							/>
						}
					</BackgroundImage>
				</div>
			</AccordionDetails>
		</Accordion >
	)
}

export default Family;
