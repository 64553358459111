import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IScoreProps } from 'common/types';
import { GatsbyImage } from 'gatsby-plugin-image';

const OverallScore: FC<IScoreProps> = ({ score, image }) => {
	const { t } = useTranslation();

	return (
		<div
			className='
				relative
				inline-flex
				items-center
				rounded
				h-7
				mb-4
				px-2
				py-[2px]
				bg-blue
				md:h-10
				md:py-2
				lg:h-12
				lg:py-4
				lg:rounded-xl
				xl:h-16
			'
		>
			<GatsbyImage
				image={image}
				alt={t('mokenDetails.lightning')}
				objectPosition='50% 50%'
				objectFit='contain'
				className='absolute
					top-1
					left-1
					w-8
					md:w-10
					lg:w-12
					xl:w-16
				'
			/>
			<span className='attribute-text ml-8 md:ml-10 lg:ml-14 xl:ml-16'>
				{t('mokenDetails.overall')}
			</span>
			<span className='ml-auto lg:mr-2 attribute-text'>
				{score}
			</span>
		</div>
	)
}

export default OverallScore;
