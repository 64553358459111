export const cardBackground = {
	1: 'common-card.png',
	2: 'uncommon-card.png',
	3: 'rare-card.png',
	4: 'epic-card.png',
	5: 'legendary-card.png',
}

export const positionImage = {
	1: 'defender-role-icon.png',
	2: 'specialist-role-icon.png',
	3: 'attacker-role-icon.png',
}

export const sizes = {
	width: {
		xs: 'w-[110px] rounded-xl',
		sm: 'w-[220px] rounded-3xl',
		md: 'w-[310px] rounded-[1.75rem]',
		lg: 'w-[410px] rounded-[2.25rem]',
	},
	overallSize: {
		xs: 'text-xs overall-score-xs',
		sm: 'text-[1.5rem] overall-score-medium',
		md: 'text-[3rem] overall-score-large',
		lg: 'text-[4rem] overall-score-large',
	},
	nameSize: {
		xs: 'text-[6px] moken-name-xs',
		sm: 'text-[10px] moken-name-medium',
		md: 'text-[14px] moken-name-large',
		lg: 'text-[1rem] moken-name-large',
	}
}