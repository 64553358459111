import { useState } from "react";

const usePagination = (items, pageLimit) => {
  const [currentPage, setcurrentPage] = useState(1);
  const pageCount = Math.ceil(items.length / pageLimit);

  const changePage = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  const pageData = () => {
    const firstItem = (currentPage - 1) * pageLimit;
    const lastItem = firstItem + pageLimit;
    return items.slice(firstItem, lastItem);
  };

	const nextPageData = () => {
    const firstItem = currentPage * pageLimit;
    const lastItem = firstItem + pageLimit;
    return items.slice(firstItem, lastItem);
  };

  return {
    currentPage,
    pageCount,
    changePage,
    pageData,
		nextPageData,
  };
}

export default usePagination;