import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { IStatBoxProps } from 'common/types';

const StatBox: FC<IStatBoxProps> = ({ label, value, icon, iconAlt }) => {
	return (
		<div className='attributes'>
			<GatsbyImage
				image={icon}
				alt={iconAlt}
				objectFit='contain'
				objectPosition='50% 50%'
				className='w-6 mr-2 lg:w-12 xl:w-14'
			/>
			<span className='attribute-text'>
				{label}
			</span>
			<span className='ml-auto lg:mr-2 attribute-text'>
				{value}
			</span>
		</div>
	)
}

export default StatBox;